import React from 'react'
import './DayPage.scss'

const DayPage = (props) => (
    <div className="DayPage">
        <h1> DayPage </h1>
        <p>{ props.match.params.day }</p>
    </div>
)

export default DayPage