import React, { Component, useContext } from 'react'
import Month from 'calendar-months'; // https://www.npmjs.com/package/calendar-months
import moment from 'moment';
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import './HomePage.scss'
import ScrollContainer from '../../components/ScrollContainer'
import ItemsManager from './ItemsManager'
import { AuthContext } from '../../Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HomePage = (props) => {
    const user = useContext(AuthContext);
    console.log('USER:', user)

    if(user===0){
        return (
            <div className="HomePage">
                <FontAwesomeIcon icon={["fal", "spinner"]} size="lg" spin />
            </div>
        );
    }

    return (
        <div className="HomePage">
            {
                user ?
                <div className="DaysContainer">
                    <ScrollContainer>
                        <ItemsManager />
                    </ScrollContainer>
                </div>
                :
                <div>
                    Log in to continue
                </div>
            }
        </div>
    )
}

// { days.map(day => <Day key={day.format('YYYY-MM-DD')} day={day} today={today} />) }

export default HomePage