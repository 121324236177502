import React, { useContext } from "react";
// import { AuthContext } from '../../Auth'
// import { Link} from 'react-router-dom'
import './Footer.scss'


const Footer = () => {
    // const user = useContext(AuthContext);
    return (
        <div className="Footer">
            Planner.Live
        </div>
    )
}

export default Footer