import React, { useContext } from "react";
import { AuthContext } from '../../Auth'
import { Link} from 'react-router-dom'
import firebase, {auth} from 'firebase/app'
import './Header.scss'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const login = () => {
    auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
}
const logout = () => { auth().signOut() }
const LoginButton = () => <button onClick={login}>Login</button>
const LogoutButton = () => <button onClick={logout}>Logout</button>

const Header = () => {
    const user = useContext(AuthContext);
    return (
        <div className="Header">
            <h1>Planner.Live</h1>

            { !user ? <LoginButton /> :
                <span>Welcome {user.email} <LogoutButton /></span>
            }
        </div>
    )
}

export default Header