import React, { Component } from 'react'
import DraftContainer from '../../components/DraftContainer'
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle'
import firebase, { db } from '../../config/firebase'

/*
Determining the dimensions of elements
https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements
*/


/**
    bool offScreen - Item will need to know whether it is completely off-screen
        which will remove it from the DOM
    bool bottomAboveViewportBottom - if true then another item needs to be added below
    bool topBelowViewportTop - if true then another item needs to be added above

    Caution: if item is added above, then it's positioning will depend on it's height.
 */
class Item extends Component {
    constructor(props) {
        super(props);
        this.rootEl = React.createRef();
        this.state = {
            editorState: EditorState.createEmpty(),
            inViewport: false,  // at least partially in viewport
            // height: 0,
            //raw: null
        };
        this.save = throttle(this.save, 3000)
    }

    componentDidMount() {
        this.requestSetHeight();
        // this.checkWhetherInViewport();
        if(this.props.user) {
            this.getData();
        }
    }
    componentDidUpdate(prevState, prevProps) {
        const viewportHeight = this.props.height;
        if ( this.rootEl.current && this.rootEl.current.offsetHeight !== viewportHeight ) {
            this.requestSetHeight();
        }
        // if(!prevProps.user && this.props.user){
        //     this.getData();
        // }
    }


    getData = () => {
        const { user, date } = this.props;
        // console.log('[getData]', date)

        const ref = db
            .collection('userdata')
            .doc(user.uid)
            .collection('dates')
            .doc(date);
        
        ref.get()
            .then( doc => {
                if(doc.exists){
                    const data = doc.data();
                    if(!data.raw){ return; }
                    this.setFromRaw(JSON.parse(data.raw))
                }
            })
    }

    setFromRaw = (raw) => {
        // console.log('[setFromRaw]')
        if(!raw){ return }
        const currentSelection = this.state.editorState.getSelection()
        const editorState = EditorState.createWithContent(convertFromRaw(raw));
        const stateWithContentAndSelection = EditorState.forceSelection(editorState, currentSelection)
        this.setState({ editorState: stateWithContentAndSelection});
    }


    save = () => {
        const { user, date } = this.props;
        const raw = convertToRaw(this.state.editorState.getCurrentContent());
        console.log('[SAVE ITEM]', date, raw, user.uid);

        // `userdata/${user.uid}/dates/${date}`

        const ref = db
            .collection('userdata')
            .doc(user.uid)
            .collection('dates')
            .doc(date);

        ref.set({
            raw: JSON.stringify(raw),
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true })
            .then(()=>{ console.log('SAVED', date)})
            .catch(err=>{ console.warn('ERROR writing to Firestore:', err.message) });
    }
    


    onChange = (editorState) => {
        this.setState({
            editorState, 
            //raw: convertToRaw(editorState.getCurrentContent())
        }, this.save);
    }
    onFocus = () => {
        // console.log('FOCUS', this.props.date);
        typeof this.props.onMainRequest === "function" && this.props.onMainRequest(this.props.date);
    }

    requestSetHeight = () => {
        window.requestAnimationFrame(this.setHeight);
    };
    setHeight = () => {
        if(!this.rootEl.current) return;
        const height = this.rootEl.current.offsetHeight;

        // console.log('[setHeight]', height)
        
        if (height === this.props.height) { return; }
        // onHeightChange
        typeof this.props.onHeightChange === 'function' && this.props.onHeightChange(this.props.date, height);
        // this.setState({ height });
    };

    render(){
        const { date, scrollY, height, main, top } = this.props;
        const { editorState } = this.state;

        if(top!==top){
            return null;
        }
        
        let style = { 
            // top: scrollY + top,
            top,
            borderColor: main ? 'orange': '#2d1e03',
            opacity: height ? 1 : 0
        };


        return (
            <div
                ref={this.rootEl}
                className="Item" 
                style={style}
                >
                    <div>{ date }</div>
                    <DraftContainer
                        editorState={editorState} 
                        onChange={this.onChange} 
                        onFocus={this.onFocus} 
                        />

                    {/* <div className="debuginfo">
                        <span>topBelowViewportTop: { scrollY>=0-top?'Y':'N' } - </span>
                        <span>height: { height }</span>
                    </div> */}
                    
            </div>
        )
    }
}



Item.propTypes = {
    // onRequestMoreItemsAtTop: PropTypes.func, // onRequestMoreItemsAtTop(item)
    // onRequestMoreItemsAtBottom: PropTypes.func, // onRequestMoreItemsAtBottom(item)
    onEnterViewport: PropTypes.func, // onEnterViewport(item)
    onLeaveViewport: PropTypes.func, // onLeaveViewport(item)
    onHeightChange: PropTypes.func, // onHeightChange(date, height)
    user: PropTypes.object, // firebase user object
};



export default Item;