import React, { createContext } from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const ScrollContext = createContext(null);


class ScrollContainer extends React.Component {
    constructor(props) {
        super(props);
        this.rootEl = React.createRef();
    }
    
    state = {
        scrollX: 0,
        scrollY: 0,
        width: 0,
        height: 0,
        scrolling: false
    };
    scrollDeltaX = 0;
    scrollDeltaY = 0;

    componentDidMount() {
        this.setup();
    }
    componentWillUnmount() {
        this.destroy();
    }

    setup = () => {
        this.onResize();
        this.startListeningScroll();
        this.startListeningResize();
    };
    destroy = () => {
        this.stopListeningScroll();
        this.stopListeningResize();
    };

    startListeningScroll = e => {
        // console.log('el:', this.rootEl.current)
        if (!this._scrollListeningStarted) {
            this.rootEl.current.addEventListener( "wheel", this.onMousewheel, false );
            this._scrollListeningStarted = true;
        }
    };
    stopListeningScroll = e => {
        if (this._scrollListeningStarted) {
            this.rootEl.current.removeEventListener( "wheel", this.onMousewheel, false );
            this._scrollListeningStarted = false;
        }
    };
    startListeningResize = e => {
        // console.log('el:', this.rootEl.current)
        if (!this._resizeListeningStarted) {
            window.addEventListener("resize", this.onResize, false);
            this._resizeListeningStarted = true;
        }
    };
    stopListeningResize = e => {
        if (this._resizeListeningStarted) {
            window.removeEventListener("resize", this.onResize, false);
            this._resizeListeningStarted = false;
        }
    };

    onResize = e => {
        if (!this.rootEl.current && !this.state.height) {
            window.setTimeout(this.onResize, 50);
        }
        const width = this.rootEl.current.clientWidth;
        const height = this.rootEl.current.clientHeight;
        // console.log('resize', width, height);

        this.setState( s => ({ width, height }), () => {
            typeof this.props.onResize === "function" &&
            this.props.onResize({ width, height });
        } );
    };

    onMousewheel = e => {
        e.preventDefault();
        this.scrollDeltaX -= e.deltaX;
        this.scrollDeltaY -= e.deltaY;
        window.requestAnimationFrame(this.setScroll);
        this.mouseScrolling();
        return false;
    };

    setScroll = () => {
        let scrollX = 0;
        let scrollY = 0;
        this.setState( s => ({ 
            scrollX: (scrollX = s.scrollX + this.scrollDeltaX) + (this.scrollDeltaX = 0),
            scrollY: (scrollY = s.scrollY + this.scrollDeltaY) + (this.scrollDeltaY = 0)
        }), () => {
            typeof this.props.onScroll === "function" &&
            this.props.onScroll({ scrollX, scrollY });
        });
    };

    /*
        Called multiple times during scrolling/swiping, throttled
    */
    mouseScrolling = () => {
        this._mouseScrollingTimeout &&
        window.clearTimeout(this._mouseScrollingTimeout);
        this.setState( s => { 
            if (!s.scrolling) {
                typeof this.props.onScrollStart === "function" && this.props.onScrollStart();
            }
            return { scrolling: true };
        }, () => {
            this._mouseScrollingTimeout = window.setTimeout( this.mouseScrollingDone, 250 );
        });
    };

    /*
        Called once after scrolling/swiping
    */
    mouseScrollingDone = () => {
        this.setState({ scrolling: false }, () => {
            typeof this.props.onScrollDone === "function" && this.props.onScrollDone();
        });
    };

    render() {
        const { scrolling, height } = this.state;

        return (
        <div 
            ref={this.rootEl} 
            className={classNames("ScrollContainer", { scrolling })}
            >
            <ScrollContext.Provider value={this.state}>
                {this.props.children}
            </ScrollContext.Provider>
        </div>
        );
    }
}

ScrollContainer.propTypes = {
    onResize: PropTypes.func, // onResize({width, height})
    onScroll: PropTypes.func, // onScroll({scrollX, scrollY})
    onScrollStart: PropTypes.func, // onScrollStart()
    onScrollDone: PropTypes.func, // onScrollDone()
};

export default ScrollContainer;
