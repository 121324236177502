import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage/LoginPage'
import HomePage from './pages/HomePage/HomePage'
import DayPage from './pages/DayPage/DayPage'
import Auth from './Auth'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/pro-light-svg-icons'
library.add(faSpinner)


const App = () => (
  <Auth>
    <Router>
      <div className="App">

        <Header />
      
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/day/:day" component={DayPage} />
        </Switch>
        <Footer />
      </div>
    </Router>
  </Auth>
);

export default App;