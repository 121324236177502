import React, { createContext, useState, useEffect } from 'react';
import firebase from './config/firebase'

export const AuthContext = createContext(null);

const Auth = (props) => {

    const [user, setUser] = useState(0);
    
    useEffect(() => firebase.auth().onAuthStateChanged(setUser) );

    return (
        <AuthContext.Provider value={user}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default Auth;