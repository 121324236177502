import React, { Component, useContext } from 'react'
import { ScrollContext } from '../../components/ScrollContainer'
import { AuthContext } from '../../Auth'
import Item from './Item'
import memoize from 'fast-memoize'

const normalizeDate = memoize(date => {
    let mm = date.getMonth()+1;
    if(mm<10) mm = '0'+mm;
    let dd = date.getDate();
    if(dd<10) dd = '0'+dd;
    return `${date.getFullYear()}-${mm}-${dd}`;
});

const yesttomorr = (today, add) => {
    let date = new Date(today);
    date.setDate(date.getDate() + add);
    return normalizeDate(date) ;
}

const yesterday = memoize(today => yesttomorr(today, -1));
const tomorrow = memoize(today => yesttomorr(today, 1));


const itemStub = {
    // date: normalizeDate(new Date()),
    height: 0,
    top: 80
};

const today = normalizeDate(new Date());

class Items extends Component {
    // eg. { scrollY: 81, height: 507, scrolling: false }

    state = {
        itemHeights: {},
        itemTops: {
            [ today ]: 80
        },
        main: today, // id (date) of the main item
        visibleItems: [ today ]
    }

    static getDerivedStateFromProps(props, state) {

        const { scrollY, viewportHeight } = props;
        const { main, itemTops, itemHeights, visibleItems } = state;
        /*
            Given
                main item
                main item position
                main item height
                viewport height
                scrolling position
            Calculate
                which other item can be added to fill up the screen
                positionings for all items on screen based off the main item and heights

            Remove the items that go offscreen

            If main item goes offscreen
                assign another main item
    
        */

        let nextState = {
            visibleItems: visibleItems.slice(0),
            itemTops: {...itemTops}
        };

        let beforeMain = true;
        const totalItems = visibleItems.length;

        //const lastItem = visibleItems[totalItems-1];
    

        let mainItemVisibleIndex = visibleItems.indexOf(main);

        if(mainItemVisibleIndex===-1){
            mainItemVisibleIndex = 0;
            nextState.main = visibleItems[0];
        }

        // loop through items after main
        for(let i = mainItemVisibleIndex+1; i<totalItems; i++ ){
            const date = visibleItems[i];
            const height = itemHeights[date];
            const top = itemTops[date]; // abstract top, based on the absolute document positioning
            const topPos = top + scrollY; // visible top, based on the top of the viewport, changes with scroll
            const bottomPos = topPos + height; // visible top, based on the top of the viewport, changes with scroll

            // is this item's topPos below viewport bottom? -> remove it from visibleItems
            if( (topPos > viewportHeight || bottomPos < 0) && nextState.visibleItems.length > 1 ) {
                nextState.visibleItems = nextState.visibleItems.filter( (item,arr) =>  item !== date );
            }

            const prevDate = visibleItems[i-1];
            if(prevDate){
                const prevTop = nextState.itemTops[prevDate] || itemTops[prevDate];
                const prevHeight = nextState.itemHeights && nextState.itemHeights[prevDate] ? nextState.itemHeights[prevDate] : itemHeights[prevDate];
                if(typeof prevTop==='number' && typeof prevHeight==='number'){
                    nextState.itemTops[date] = prevTop + prevHeight + 1;
                }
            }
        }


         // loop through items before main
        for(let i = mainItemVisibleIndex-1; i>=0; i-- ){
            const date = visibleItems[i];
            const height = itemHeights[date];
            const top = itemTops[date]; // abstract top, based on the absolute document positioning
            const topPos = top + scrollY; // visible top, based on the top of the viewport, changes with scroll
            const bottomPos = topPos + height; // visible top, based on the top of the viewport, changes with scroll

            // is this item's topPos below viewport bottom? -> remove it from visibleItems
            if((topPos > viewportHeight || bottomPos < 0) && nextState.visibleItems.length > 1) {
                nextState.visibleItems = nextState.visibleItems.filter( item => item !== date );
            }

            const prevDate = visibleItems[i+1];
            if(prevDate){
                const prevTop = nextState.itemTops[prevDate] || itemTops[prevDate];
                // const prevHeight = nextState.itemHeights && nextState.itemHeights[prevDate] ? nextState.itemHeights[prevDate] : itemHeights[prevDate];
                // console.log('UPDATED TOP:', prevTop - height - 1, prevTop, height)
                if(typeof prevTop==='number' && typeof height==='number'){
                    nextState.itemTops[date] = prevTop - height - 1;
                }
            }
        }



        const firstItem = visibleItems[0];
        if(itemTops[firstItem] + scrollY > 0 && itemHeights[firstItem]){ // there's space at top of viewport
            const nextDate = yesterday(firstItem);
            nextState.visibleItems.unshift(nextDate);
            nextState.itemTops[nextDate] = nextState.itemTops[firstItem] - 20;
        }





        
        visibleItems.forEach( (date, i) => {
            const height = itemHeights[date];
            const top = itemTops[date]; // abstract top, based on the absolute document positioning
            const topPos = top + scrollY; // visible top, based on the top of the viewport, changes with scroll
            const bottomPos = topPos + height; // visible top, based on the top of the viewport, changes with scroll

            const isMain = date===main;
            if(isMain) beforeMain = false;

            // if(beforeMain) {
            // } else if (isMain){
            //     // is this item's topPos below viewport bottom? -> remove it from visibleItems
            //     // if((topPos > viewportHeight || bottomPos < 0 ) && nextState.visibleItems.length > 1) {
            //     //     nextState.visibleItems = nextState.visibleItems.filter( item => item !== date );
            //     // }
            // } else { // after main
            // }
            

            if(i===totalItems-1){ // last item
                if(bottomPos < viewportHeight && height){ // there's space at bottom of viewport
                    //console.log('add more items')
                    const nextDate = tomorrow(date);
                    // nextState.visibleItems = [...visibleItems, nextDate];
                    nextState.visibleItems.push(nextDate);
                    nextState.itemTops[nextDate] = top + height;
                }
            }

        }); // forEach



        // remove main item outside viewport, if there are other items in viewport
        const mainItemTopPos = itemTops[main] + scrollY;
        if((mainItemTopPos > viewportHeight || mainItemTopPos+itemHeights[main] < 0 ) && nextState.visibleItems.length > 1) {
            nextState.visibleItems = nextState.visibleItems.filter( item => item !== main );
        }


        

        // check if there's a visible main item
        if(!nextState.visibleItems.includes(main)){
            nextState.main = nextState.visibleItems[0];
        }

    
        return nextState;
    }



    // push = item => this.setState({ items: [...this.state.items, item] });
    // unshift = item => this.setState({ items: [item, ...this.state.items] });
    
    // adds one item in the beginning of the array, with date based off the first item
    unshiftPrevOne = item => this.setState(s => ({ items: [item, ...this.state.items] }));

    /* adds one item at the end of the array, with date based off the last item, and position */
    pushNextOne = item => {
        const { items } = this.state;
        if(!items.length) { return; }
        const lastItem = items[items.length-1];
        if(!lastItem.height) { return; }
                
        this.setState(s => {            
            const lastItem = items[items.length-1];
            // console.log('lastItem:',lastItem)
            const lastItemBottomPos =  lastItem.top + lastItem.height;
            return {
                items: [
                    ...s.items,
                    {
                        ...itemStub,
                        date: tomorrow(lastItem.date),
                        top: lastItemBottomPos + 1
                    }
                ] 
        }});
    }

    componentDidMount(){
        /*this.setState(s => {
            const date = normalizeDate(new Date());
            return {
                items: {
                    ...s.items,
                    [date]: {
                        ...itemStub,
                        date
                    }
                },
                main: date
            }
        })
        this.checkSpace();*/
    }

    componentDidUpdate(){
        // this.checkSpace();
    }



    /*getVisibleItems = () => {
        const { main } = this.state;
        if(!main) { return []; }
        let stateItems = this.state.items;
        let stateItemsAreClean = true;
        const mainItem = stateItems[main];
        let visibleItems = [mainItem];
        
        //let limit=10; // failsafe
        let lastItem;
        
        while(this.isItemBottomAboveViewportBottom(lastItem = visibleItems[visibleItems.length-1]) && lastItem.height>0){
            //console.log('[getVisibleItems]', lastItem.date)

            const nextDate = tomorrow(lastItem.date);
            if(!stateItems[nextDate]){
                if(stateItemsAreClean){
                    stateItems = {...stateItems};
                    stateItemsAreClean = false;
                }

                stateItems[nextDate] = {
                    top: lastItem.top + lastItem.height + 1,
                    height: 0,
                    date: nextDate
                }
                visibleItems.push(stateItems[nextDate])
                break;
            }

            const nextItem = stateItems[nextDate];
            //if(nextItem.height>0){
                visibleItems.push(stateItems[nextDate])
            //}

            
            
            //limit--;
        }

        if(!stateItemsAreClean){
            window.setTimeout(()=>{
                this.setState({
                    items: stateItems
                })
            },0);
        }

        
        //console.log('visibleItems:', visibleItems)
        return visibleItems;
    }*/

    isItemBottomAboveViewportBottom = item => this.props.scrollY+item.height <= this.props.viewportHeight-item.top;

    calculateItemProperties = item => {
        const { viewportHeight , scrollY} = this.props;
        const topPos =  scrollY + item.top;
        const bottomPos =  scrollY + item.top + item.height;
        const topBelowViewportTop =  scrollY >= 0-item.top;
        const bottomAboveViewportBottom =  scrollY+item.height <= viewportHeight-item.top;
        
        return {
            ...item, topPos, bottomPos, topBelowViewportTop, bottomAboveViewportBottom
        }
    }
    
    // checkSpace = () => {
    //     // const { viewportHeight , scrollY} = this.props;

    //     // // const itemWithProperties = this.calculateItemProperties(item);
    //     // console.log("CHECK SPACE", 
    //     //     { viewportHeight },
    //     //     JSON.stringify(this.state.items.map(this.calculateItemProperties))
    //     // );

    //     // if(!this.state.items || !this.state.items.length) {return;}
    //     // const lastItem = this.calculateItemProperties(this.state.items[this.state.items.length-1]);

    //     // if(lastItem.bottomAboveViewportBottom) {
    //     //     console.log('LOAD MOREE');
    //     //     this.pushNextOne();
    //     // }
    // }

    handleItemEnteringViewport = (item) => {
        console.log('ITEM ENTERED', item.date);
    }
    handleItemLeavingViewport = (item) => {
        console.log('ITEM LEFT', item.date);
    }

    handleItemMainRequest = (date) => {
        // console.log('make main:', date)
        this.setState({ main: date });
    }

    handleItemHeightChange = (date, height) =>{
        this.setState(s => ({
            itemHeights: {
                ...s.itemHeights,
                [date]: height
            }
        }))
        /*this.setState( s => ({
            items: {
                ...s.items,
                [item.date]: {
                    ...s.items[item.date],
                    height
                }
            }
        }))*/
    }

    // loadAtTop = () => {
    //     // window.setTimeout(()=>{
    //     //     this.unshift({
    //     //         date: yesterday(this.state.items[0].date),
    //     //         main: true
    //     //     })
    //     // },5000)
    // }
    // loadAtBottom = () => {
    //     // this.push({
    //     //     date: normalizeDate(new Date()),
    //     //     main: true
    //     // })
    // }
    
    render() {
        const { scrollY, viewportHeight, user } = this.props;
        const { items, main, visibleItems, itemHeights, itemTops } = this.state;

        return (
            <div className="Items">
                {/* <div style={{position:'fixed',top:10,right:500}}>{ scrollY }</div> */}
                { visibleItems.map( (date, index) => (
                    <Item 
                        key={date}
                        date={date}
                        index={index}
                        height={itemHeights[date]} 
                        top={itemTops[date]+scrollY}
                        // totalItems={items.length}
                        main={date===main}
                        scrollY={scrollY}
                        viewportHeight={viewportHeight} 
                        // onRequestMoreItemsAtTop={this.loadAtTop}
                        // onRequestMoreItemsAtBottom={this.loadAtBottom}
                        onEnterViewport={this.handleItemEnteringViewport}
                        onLeaveViewport={this.handleItemLeavingViewport}
                        onHeightChange={this.handleItemHeightChange}
                        onMainRequest={this.handleItemMainRequest}
                        user={user}
                    />
                ) )}
            </div>
        )
    }
}

const ItemsManager = props => {
    const viewport = useContext(ScrollContext);
    const user = useContext(AuthContext);
    // eg. {"scrollX":54,"scrollY":81,"width":980,"height":507,"scrolling":false}

    return <Items 
        scrollY={viewport.scrollY}
        // viewportWidth={viewport.width}
        viewportHeight={viewport.height}
        scrolling={viewport.scrolling}
        user={user}
    />
}

export default ItemsManager