import React, { useContext } from "react";
import { AuthContext } from '../../Auth'
import firebase, {auth} from 'firebase/app'
import 'firebase/auth'

const login = () => {
    auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
}
const logout = () => { auth().signOut() }

const LoginButton = () => <button onClick={login}>Login</button>
const LogoutButton = () => <button onClick={logout}>Logout</button>

const LoginPage = () => {
    const user = useContext(AuthContext);
    return (
        <div className="LoginPage">
            <h1>Login</h1>
            { !user ? <LoginButton /> :
                <p>Logged in as {user.email} <LogoutButton /></p>
            }
        </div>
    )
}

export default LoginPage