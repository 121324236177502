/**
 https://github.com/9jaswag/pusher-editor/blob/master/src/pages/index.js
 */

import React from "react";
import PropTypes from "prop-types";
import { stateToHTML } from "draft-js-export-html";
import {
    Editor, // https://draftjs.org/docs/api-reference-editor
    EditorState, // https://draftjs.org/docs/api-reference-editor-state
    RichUtils, // https://draftjs.org/docs/api-reference-rich-utils
    convertToRaw,
    convertFromRaw
} from "draft-js";

class DraftEditor extends React.Component {
    constructor(props) {
        super(props);
        this.rootEl = React.createRef();
        this.state = {
            // editorState: EditorState.createEmpty(),
            height: 0,
            html: ""
        };
    }
    componentDidMount() {
        this.requestSetHeight();
    }
    componentDidUpdate(prevState) {
        if (
            this.rootEl.current &&
            this.rootEl.current.editorContainer.clientHeight !== this.state.height
        ) {
            this.requestSetHeight();
        }
    }

    onChange = editorState => {
        // const raw = convertToRaw(editorState.getCurrentContent());
        // this.saveEditorContent(raw);

        // console.log('raw:', raw );
        // this.setState({ editorState }, () => {
            this.props.onChange(editorState);
            // this.requestSetHeight();
        // });
    };

    onFocus = () => {
        typeof this.props.onFocus === 'function' && this.props.onFocus();
    }

    requestSetHeight = () => {
        window.requestAnimationFrame(this.setHeight);
    };

    setHeight = () => {
        if(!this.rootEl.current) {
            return;
        }
        const height = this.rootEl.current.editorContainer.clientHeight;
        if (height === this.state.height) {
            return;
        }
        this.setState({ height });
    };

    saveEditorContent(data) {
        // console.log("SAVE", data);
        //localStorage.setItem('editorData', JSON.stringify(data));
    }

    getSavedEditorData() {
        return null;
        const savedData = localStorage.getItem("editorData");
        return savedData ? JSON.parse(savedData) : null;
    }

    handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return "handled";
        }
        return "not-handled";
    };

    render() {
        const { height } = this.state;
        const { editorState } = this.props;
        let html = "";
        if (editorState) {
            const contentState = editorState.getCurrentContent();
            html = stateToHTML(contentState);
        }

        return (
            <div className="Editor">
                {/* <div className="height">{height}</div> */}
                <Editor
                    ref={this.rootEl}
                    editorState={editorState}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    handleKeyCommand={this.handleKeyCommand}
                />
                {/* <div className="Result" dangerouslySetInnerHTML={{ __html: html }} /> */}
            </div>
        );
    }
}

DraftEditor.propTypes = {
    onHeightChange: PropTypes.func,
    editorState: PropTypes.object,
    onChange: PropTypes.func,
}

export default DraftEditor;
