import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/database';
import 'firebase/firestore';
// import 'firebase/messaging';
// import 'firebase/storage';


const config = {
    apiKey: "AIzaSyCiizoFbnOpKYIs3D8QIvSituocuRteRiM",
    authDomain: "mediaaplaan.firebaseapp.com",
    databaseURL: "https://mediaaplaan.firebaseio.com",
    projectId: "mediaaplaan",
    storageBucket: "mediaaplaan.appspot.com",
    messagingSenderId: "385654637364"
};

firebase.initializeApp(config);

export const db = firebase.firestore();
export const auth = firebase.auth();
// export const storage = firebase.storage();

export default firebase;